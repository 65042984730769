<template>
  <div class="c-resume__wrapper">
    <div class="c-heroimage">
      <img src="images/banner.jpg">
      <div class="c-caption">
        Conducting a storyboarding exercise with a group of product managers. Photo credit: Diana Freeman-Baer
      </div>
    </div>
    <div class="c-resume">
      <div class="c-block">
        <h3>EXPERIENCE</h3>

        <div class="c-job__header">
          <div class="c-job__company">
            RefleXion Medical<span class="c-job__location">, Hayward, CA</span>
          </div>
        </div>

        <div class="o-layout">
          <div class="o-layout__item u-1/1 u-3/5@sm">
            <div class="c-job">
              <div class="c-job__dates">March 2020 - December 2023</div>
              <div class="c-job__title">Senior User Experience Designer</div>
              
                <div class="c-job__description">
                  <p>
                    RefleXion is a healthcare start-up building a machine for treating cancer with radiation using a new and unique way of tracking tumors. This required understanding the complex workflows of clinical care teams made up of different roles, responsibilities and timelines working collaboratively to plan and deliver treatment to patients. This is why it was a great opportunity to utilize Participatory Design, speeding up the work and reducing time to validate designs.
                  </p>
                  <p>
                    My primary focus was leading the design of the Medical Physics Workspace, a new software solution to increase self-service, a major business goal. I owned the entire design process, from performing contextual inquiry, to making workflows and wireframes, and conducting workshops with customers. I worked closely with a cross-functional team including Product Management and Software to define the product strategy and understand technical feasibility. The first version was released in Q2 of 2023 and is used on a daily basis at all customer sites.
                  </p>
                  <p>
                    While the Medical Physics Workspace was my biggest project, I had multiple efforts that I worked on at the same time. I led design on usability testing to get FDA clearance on a new software release, conducted research to understand user needs and build empathy, and presented insights to the Executive team and Research and Design group. I designed and built wireframes to improve features for treatment planning and delivery, increasing satisfaction and ease-of-use scores, and led an exploration of a metrics dashboard to provide insight into customer usage. I even built an internal website in Angular and Sass/HTML/CSS to showcase the design system and provide guidelines and best practices for use, improving communication with the software engineering team.
                  </p>
                  <p>
                    My most memorable experience, though, was seeing someone treated for cancer on the system that I had worked on.
                  </p>
                </div>
            </div>
          </div>
          <div class="o-layout__item u-1/1 u-2/5@sm c-portfolio-links">
            <h4>Projects</h4>
            <router-link to="/portfolio/MedPhysics">
              <div class="c-portfolio-link">
                <div class="c-piece__summary">
                  <h3>Medical Physics Workspace</h3>
                </div>
                <img src="images/portfolio/rx/water_tank_cropped.jpg">
              </div>
            </router-link>
            <router-link to="/portfolio/Evaluation">
              <div class="c-portfolio-link">
                <div class="c-piece__summary">
                  <h3>Treatment Plan Evaluation</h3>
                </div>
                <img src="images/portfolio/rx/inuse-edited.jpg">
              </div>
            </router-link>
          </div>
        </div>

        <div class="c-job__header">
          <div class="c-job__company">
            OSISoft<span class="c-job__location">, San Leandro, CA</span>
          </div>
        </div>

        <div class="o-layout">
          <div class="o-layout__item u-1/1 u-3/5@sm">
            <div class="c-job">
              <div class="c-job__dates">July 2018 - March 2020</div>
              <div class="c-job__title">Senior User Experience Designer</div>
              <div class="c-job__description">
                <p>
                  A big part of my work at OSISoft was advocating for design and working to establish a design process at a company trying to figure out its relationship with design.
                </p>
                <p>
                  When OSISoft wanted to open up new markets for remote operations and fleet management, I was pulled in to help with this new SaaS offering, dubbed Canvas. Without an established design process, though, this meant designing the process while designing the product. To help with this, I conducted a service blueprinting exercise to look for touchpoints where design could add value, and ran a workshop with product managers and software engineers to build relationships and collaboratively define the process. The workshop was a success and became a basis for our working relationship.
                </p>
                <p>
                  Also as part of the project, I conducted research with customers, iterated rapidly on wireframes, and worked on a new web-based design system. I authored a framework for adapting our legacy applications for mobile, and driving mobile-friendly patterns for future software, in addition to mentoring early career designers.
                </p>
              </div>
            </div>
          </div>
          <div class="o-layout__item u-1/1 u-2/5@sm c-portfolio-links">
            <h4>Projects</h4>
            <router-link to="/portfolio/Canvas">
                <div class="c-portfolio-link">
                  <div class="c-piece__summary">
                    <h3>Mobile Friendly Remote Ops.</h3>
                  </div>
                  <img src="images/portfolio/canvas/Master.png">
                </div>
              </router-link>
          </div>
        </div>

        <div class="c-job__header">
          <div class="c-job__company">
            GE Digital<span class="c-job__location">, San Ramon, CA</span>
          </div>
        </div>

        <div class="o-layout">
          <div class="o-layout__item u-1/1 u-3/5@sm">
            <div class="c-job">
              <div class="c-job__dates">June 2014 - May 2018</div>
              <div class="c-job__title">Staff User Experience Designer</div>
              <div class="c-job__description">
                <p>
                  I joined GE as part of a rotational program that meant working on projects at different divisions in the company. This meant doing research and design in a variety of places including a jet engine repair shop, a power utility control room, next generation locomotive cab, and a wind farm with a prototype wind turbine, to name a few. 
                </p>
                <p>
                  These different very physical settings meant exploring some fun ways of conducting research. For the next generation locomotive cab, I had the idea to build a paper prototype of a locomotive cab using foam blocks, paper and post-it notes, and have the engineers and conductors act out scenarios in the prototype. It was a lot of fun and there was a lot of good-natured cursing.
                </p>
                <p>
                  Eventually I settled at GE Digital where my primary focus was Predix GE’s cloud platform for the Industrial Internet of Things (IIoT). My biggest project was a software solution for DevOps (CI/CD) to help increase adoption and speed deployment. I also mentored several early career designers in developing their own design processes as part of work on real-world, commercial products.
                </p>
                <p>
                  At one point, I realized that GE was having trouble explaining the value of Predix, so I conducted research with stakeholders and used systems thinking to draw diagrams that helped communicate the relationship between different products, helping to improve company wide messaging. The diagram received an incredible amount of interest and was used as part of presentations by directors and executives.
                </p>
                <p>
                  Fun side note: I coded the original version of the stepper component for the Predix UI Design System.
                </p>
              </div>
            </div>
          </div>
          <div class="o-layout__item u-1/1 u-2/5@sm c-portfolio-links">
            <h4>Projects</h4>
            <router-link to="/portfolio/DataJourney">
              <div class="c-portfolio-link">
                <div class="c-piece__summary">
                  <h3>Data Journey</h3>
                </div>
                <img src="images/portfolio/data_journey/Data_Journey.jpeg">
              </div>
            </router-link>
            <router-link to="/portfolio/CICD">
              <div class="c-portfolio-link">
                <div class="c-piece__summary">
                  <h3>Software Deployment</h3>
                </div>
                <img src="images/portfolio/cicd/Solano_RepoPage.png" class="e-img--bordered__small">
              </div>
            </router-link>
          </div>
        </div>

        <div class="c-job u-1/1 u-4/5@sm">
          <div class="c-job__header">
            <div class="c-job__company">
              Indiana University<span class="c-job__location">, Bloomington, IN</span>
            </div>
            <div class="o-layout">
              <div class="o-layout__item u-1/1 u-2/3@sm">
                <div class="c-job__title">Research Assistant</div>
              </div>
              <div class="o-layout__item u-1/1 u-1/3@sm">
                <div class="c-job__dates">May 2013 - June 2014</div>
              </div>
            </div>
          </div>
          <div class="c-job__description">
            <p>
              While in grad school, I worked for Profs. Jeffrey and Shaowen Bardzell on a grant from Intel focused on exploring anxiety and the quantifiable self.
            </p>
            <p>
              For my part, I helped several PhD students with developing the protocols and complementary research materials, and building the software and devices for their studies. This ran the gamut from manufacturing tangible interactions (physical devices) for a long-term field study, to building websites for participants to explore their data.
            </p>
          </div>
        </div>


        <div class="c-job u-1/1 u-4/5@sm">
          <div class="c-job__header">
            <div class="c-job__company">
              Passport Health Communications<span class="c-job__location">, Franklin, TN</span>
            </div>
            <div class="o-layout">
              <div class="o-layout__item u-1/1 u-2/3@sm">
                <div class="c-job__title">Software Developer II/User Experience Developer</div>
              </div>
              <div class="o-layout__item u-1/1 u-1/3@sm">
                <div class="c-job__dates">July 2010 - October 2012</div>
              </div>
            </div>
          </div>
          <div class="c-job__description">
            <p>
              At Passport, I was the sole front-end developer for a multi-year initiative to completely redesign their flagship suite of products for Patient Access, part of Revenue Cycle Management. We were integrating six different applications, many from acquisitions, to create a holistic user experience. I built the entire UI from scratch in HTML/CSS and JavaScript/jQuery. For each stage, we identified a beta hospital who we worked with to validate requirements, conduct user interviews, and perform usability testing.
            </p>
            <p>
              As part of the update, I designed, developed, and tested a brand new interactive display of health benefit information, for which I received a patent.
            </p>
          </div>
        </div>

        <div class="c-job u-1/1 u-4/5@sm">
          <div class="c-job__header">
            <div class="c-job__company">
              McKesson Corp.<span class="c-job__location">, Nashville, TN</span>
            </div>
            <div class="o-layout">
              <div class="o-layout__item u-1/1 u-2/3@sm">
                <div class="c-job__title">Software Engineer</div>
              </div>
              <div class="o-layout__item u-1/1 u-1/3@sm">
                <div class="c-job__dates">October 2006 - July 2010</div>
              </div>
            </div>
          </div>
          <div class="c-job__description">
            <p>
              At McKesson, I was part of a team embedded at Vanderbilt University Medical Center. We collaborated with VUMC on developing new features for inpatient hospital software, including an order-entry and decision support system.
            </p>
            <p>
              In addition to software development, I had the opportunity to assist Dr. Anne Miller, a human factors researcher at Vanderbilt, with usability testing a sepsis protocol application which she later published. In addition to building an interactive prototype for the study, I moderated several tests with doctors and nurse practitioners.
            </p>
          </div>
        </div>

        <div class="c-job c-job__final u-1/1 u-4/5@sm">
          <div class="c-job__header">
            <div class="c-job__company">
              Vero Systems Group<span class="c-job__location">, Nashville, TN</span>
            </div>
            <div class="o-layout">
              <div class="o-layout__item u-1/1 u-2/3@sm">
                <div class="c-job__title">Rich Internet Application Developer</div>
              </div>
              <div class="o-layout__item u-1/1 u-1/3@sm">
                <div class="c-job__dates">July 2005 - October 2006</div>
              </div>
            </div>
          </div>
          <div class="c-job__description">
            <p>
              Vero was a small startup for auto auction software. I designed and built software, including custom user interface components, for a touch screen display with badge and label printing.
            </p>
          </div>
        </div>
      </div>

      <div class="c-block">
        <h3>EDUCATION</h3>

        <div class="c-job u-4/5@sm">
          <div class="c-job__header">
            <div class="c-job__company">
              Indiana University<span class="c-job__location">, Bloomington, Indiana</span>
            </div>
            <div class="o-layout">
              <div class="o-layout__item u-1/1 u-2/3@sm">
                <div class="c-job__title">
                  Master of Science in Human-Computer Interaction
                </div>
              </div>
              <div class="o-layout__item u-1/1 u-1/3@sm">
                <div class="c-job__dates">May 2014</div>
              </div>
            </div>
          </div>
        </div>

        <div class="c-job u-4/5@sm">
          <div class="c-job__header">
            <div class="c-job__company">
              Case Western Reserve University<span class="c-job__location">, Cleveland, Ohio</span>
            </div>
            <div class="o-layout">
              <div class="o-layout__item u-1/1 u-2/3@sm">
                <div class="c-job__title">Bachelor of Arts in Computer Science and English</div>
              </div>
              <div class="o-layout__item u-1/1 u-1/3@sm">
                <div class="c-job__dates">August 2005</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '../assets/scss/_mixins.scss';

.c-resume {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.c-job {
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.c-job__final {
  padding-bottom: 0;
}

.c-job__header {
  padding-top: 0.5rem;
}

.c-job__company {
  font-weight: bold;
  line-height: 1.4rem;
}

.c-job__location {
  font-weight: normal;
}

.c-job__title {
  font-style: italic;
  line-height: 1.4rem;
}

.c-job__dates {
  line-height: 1.4rem;
  float: right;
}

.c-job__description {
  padding-top: 0.5rem;

  p {
    margin-bottom: 0.5rem;
  }
}

.c-portfolio-links {
  padding-left: 4rem;

  h3, h4 {
    padding-top: 0;
  }

  img {
    @include mixins.pop;

    &:hover {
      @include mixins.drop;
    }
  }
}

.c-portfolio-link {
  padding-top: 0.5rem;
  padding-bottom: 1rem;

  .c-piece__summary {
    padding: 0.5rem 0;
  }
}

@media screen and (min-width: 720px) {
  .c-job__dates {
    text-align: right;
  }
}
</style>