<script setup>
</script>

<template>
    <div id="Atlas" class="c-piece">
      <div class="c-piece__section">
        <h2>Atlas</h2>
        <div class="o-subtitle">Styling a Design System to Keep Out of the Way<br />
          <span class="o-context">(Design Systems + Visual Design)</span>
        </div>
      </div>
      <div class="c-piece__section">
        <h3>Challenge</h3>
        <p>
          I was tasked with creating a new visual style for our up-and-coming design system
          that was similar to existing products, but also improved upon their usability and
          accessibility. The question was: how do I update the visual design, while still keeping
          it familiar, and improving the support of the underlying workflows?
        </p>
      </div>
      <div class="c-piece__section">
        <h3>Outcome</h3>
        <p>
          While still in development, our design system is going to be a tech-agnostic style guide
          that can be incorporated into a variety of different tech stacks, creating a consistent
          visual style across products, with a focus on supporting industrial use cases.
        </p>
        <h4>What I Learned</h4>
        <p>
          Design systems can be very powerful and very useful things, but they are far from
          universal. It's not enough to simply take an existing design system and theme the colors
          for your company's brand. Most open source design systems are targeted to a consumer
          audience and don't take into consideration the specific requirements of an enterprise,
          or especially an industrial, context.
        </p>
      </div>
      <div class="c-piece__section">
        <h3>Solution</h3>
        <div class="o-layout">
          <div class="o-layout__item u-1/1 u-1/2@sm">
            <div class="c-imageholder">
            <img src="/images/portfolio/atlas/Specs.png">
            </div>
            <div class="c-imageholder">
              <img src="/images/portfolio/atlas/Specs_Dark.png">
            </div>
            <div class="c-caption">
              Specs for a SCSS based implementation,<br />for both light and dark modes
            </div>
          </div>
          <div class="o-layout__item u-1/1 u-1/2@sm">
            <div class="c-imageholder">
              <img src="/images/portfolio/atlas/Guidelines.png">
            </div>
            <div class="c-caption">Guidelines for proper usage of different button styles</div>
          </div>
        </div>
        <p>
          This project is composed of two parts: a SCSS library with style definitions and basic
          CSS animations for a base set of components, and comprehensive documentation on best
          practices for using certain components when building a UI.
        </p>
        <p>
          This is an ongoing project. Please <a href="https://www.linkedin.com/in/michaelcohnmoreau/"
            target="_blank">contact me</a> if you are interested in learning more.
        </p>
      </div>
      <div class="c-piece__section">
        <h3>Process</h3>
        <h4>Initial designs: Jul 2018</h4>
        <div class="o-layout">
          <div class="o-layout__item u-1/1 u-1/2@sm">
            <p>
              While there are an incredible number of resources on design systems, and a lot of
              open-source design frameworks, we needed to make sure that whatever we built actually
              supported our use cases. "Off the shelf" solutions wouldn't cut it.
            </p>
            <p>
              This design system is being developed in tandem with a visualization framework for
              viewing industrial data. Since the software we are creating is
              a platform, I looked to other open-ended applications like Photoshop and Sketch as
              examples. We wanted the chrome to be available when needed, but otherwise to fade
              into the background. This meant an unobtrusive design, with low contrast, and
              considered use of bold colors.
            </p>
          </div>
          <div class="o-layout__item u-1/1 u-1/2@sm">
            <div class="c-imageholder">
              <img src="/images/portfolio/atlas/Selection.png">
            </div>
            <div class="c-caption">
              Comparing the existing style, a previous design system, and some common frameworks.
            </div>
          </div>
        </div>
        <p>
          For the aesthetic, I wanted to create a sense of physicality since our customers
          work with large, physical objects. Instead of going the route of Material Design, though,
          I started with the company logo (a squat, 3D cylinder), and looked to GUIs from the 90s
          for inspiration. This style is similar to other tools our customers use, which are seen
          as trustworthy and reliable; qualities we also want to convey.
        </p>
        <p>
          In addition to the visual design, it was my responsibility to come up with the specs,
          and guidelines for proper usage of the components. I even jumped into the SCSS a bit
          to help move the project forward.
        </p>
        <h4>Pause: Sep 2018</h4>
        <p>
          The project was de-prioritized due to a focus on other engineering projects, and the
          technical effort it would require to reconcile existing, disparate systems.
        </p>
        <h4>Re-Start: May 2019</h4>
        <p>
          With the planned release of a new suite of cloud based solutions, I saw this as an
          opportunity to bring this project back to life. The new software didn't have the baggage
          of existing, legacy applications, and so I developed a plan based on Brad Frost's atomic
          design to show how it could be adopted and even speed up development work. I am
          continuing to work with engineering on a plan to roll out the new system.
        </p>
      </div>
    </div>
</template>
