<template>
  <div id="Portfolio" class="c-portfolio">

    <div id="Contents" class="c-contents">
      <router-view></router-view>

      <div id="TableOfContents" class="c-gallery" v-if="this.$route.name == 'Portfolio'">

        <div class="o-layout c-block">
          <div class="o-layout__item u-1/1 u-1/2@sm">
            <router-link to="/portfolio/DataJourney">
              <img src="images/portfolio/data_journey/Data_Journey.jpeg">
              <div class="c-piece__summary">
                <h3>Data Journey</h3>
                <p class="o-subtitle">
                  Making Complex Concepts Easy to Understand
                </p>
                <p class="o-context">
                  (Systems Thinking + Visual Communication)
                </p>
              </div>
            </router-link>
          </div>

          <div class="o-layout__item u-1/1 u-1/2@sm">
            <router-link to="/portfolio/MedPhysics">
              <img src="images/portfolio/rx/water_tank_cropped.jpg">
              <div class="c-piece__summary">
                <h3>Medical Physics Workspace</h3>
                <p class="o-subtitle">
                  Utilizing Participatory Design with Expert Users
                </p>
                <p class="o-context">
                  (Product Design + Participatory Design)
                </p>
              </div>
            </router-link>
          </div>

        </div>
        <div class="o-layout c-block">

          <div class="o-layout__item u-1/1 u-1/2@sm">
            <router-link to="/portfolio/Evaluation">
              <img src="images/portfolio/rx/inuse.jpg">
              <div class="c-piece__summary">
                <h3>Treatment Plan Evaluation</h3>
                <p class="o-subtitle">
                  Iterative Improvement of Existing Functionality
                </p>
                <p class="o-context">
                  (Iterative Design + Microinteractions)
                </p>
              </div>
            </router-link>
          </div>
          <div class="o-layout__item u-1/1 u-1/2@sm">
            <router-link to="/portfolio/CICD">
              <img src="images/portfolio/cicd/Solano_RepoPage.png" class="e-img--bordered__small">
              <div class="c-piece__summary">
                <h3>Cloud Software Deployment</h3>
                <p class="o-subtitle">
                  A Solution to Improve Processes with Teams
                </p>
                <p class="o-context">
                  (End-to-End Product Design)
                </p>
              </div>
            </router-link>
          </div>
        </div>

        <hr />

        <div class="c-piece">
          <h3>Other Projects</h3>
          <div class="o-layout">
            <div class="o-layout__item u-1/1 u-1/2@sm">
              <router-link to="/portfolio/Testing">
                <p>Usability Testing a Medical Device</p>
                <p class="o-context">(Usability Testing + Hazard Analysis)</p>
              </router-link>
            </div>
            <div class="o-layout__item u-1/1 u-1/2@sm">
              <router-link to="/portfolio/Canvas">
                <p>Canvas: Mobile Friendly Remote Operations</p>
                <p class="o-context">(Product Design + Service Design)</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="c-bottomlinks c-piece" v-if="this.$route.name !== 'Portfolio'">
        <router-link to="/portfolio">
          <h4>All</h4>
        </router-link>
        <router-link to="/portfolio/DataJourney">
          <h4>Data Journey</h4>
        </router-link>
        <router-link to="/portfolio/MedPhysics">
          <h4>Medical Physics Workspace</h4>
        </router-link>
        <router-link to="/portfolio/Evaluation">
          <h4>Plan Evaluation</h4>
        </router-link>
        <router-link to="/portfolio/CICD">
          <h4>Cloud Software Deployment</h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@use '../assets/scss/_mixins.scss';

.c-portfolio {
  margin: 0 auto;
}

.c-gallery {

  .o-intro {
    text-align: center;
  }
  
  img {
    max-height: 280px;
    width: 100%;
    object-fit: cover;
    object-position: 100% 0;

    @include mixins.pop;

    &:hover {
      @include mixins.drop;
    }
  }
}

.c-bottomlinks {
  padding-top: 1.6rem;
  text-align: center;

  a {
    border: 0;

    &:hover h4 {
      color: #A4A4A4;
    }
  }

  h4 {
    padding: 0 20px;
    display: inline;
    color: #CCCCCC;
  }

  a.router-link-exact-active {
    h4 {
      color: #222222;
    }
  }
}

.c-piece__summary {
  padding: 8px;
}

.c-piece {
  margin-top: 1rem;

  .c-piece__section {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;

    &.c-piece__section--textonly {
      max-width: 820px;
    }

    p {
      padding-top: 0.8rem;
    }

    img {
      cursor: zoom-in;
    }

    .c-imageholder {
      @include mixins.jump__prep;
      
      &:hover {
        @include mixins.jump;
      }
    }

    aside, .o-layout {
      .c-imageholder {
        margin-left: 0;
        margin-right: 0;
      }
    }

    ol, ul {
      margin-left: 1.6rem;
      padding-top: 0.8rem;
    }

    li {
      padding-left: 0.8rem;
    }
  }
}

/* Image only with optional caption */
.c-piece__gallery {
  margin-top: 1.6rem;
  text-align: center;

  & > * {
    padding-top: 1.6rem;
  }
}

.c-piece--continued {
  margin-top: 0;
  padding-top: 0;
}
</style>