<template>
  <div class="c-about">
    <div class="o-layout c-intro">
      <div class="o-layout__item u-1/1 u-1/2@sm c-callout">
        <p>I am a Product/User Experience Designer with a background in Software Engineering.</p>
        <p>I am passionate about the ability of design to improve people's lives.</p>
        <p>And I like to draw :-)</p>
      </div>
      <div class="o-layout__item u-1/1 u-1/2@sm c-callout c-callout__center-align">
        <img src="images/about/portrait-nu.jpg">
        <div class="c-caption">
          Hello!
        </div>
      </div>
    </div>

    <div class="c-bio u-text--large">
      <p>
        I like to think of myself as an expert on experts. The majority of my career has been designing for, and with, people who have deep knowledge of complex domains. It’s why I’m such a big fan of Participatory Design.
      </p>
      <p>
        I have conducted research and design in an airplane jet engine repair shop, a locomotive cab, a power utility control room, a wind farm with a prototype wind turbine, a flow battery startup, and a radiation oncology cancer center, among others.
      </p>
      <p>My favorite design joke:</p>
      <div class="c-list">
        Q: How many designers does it take to screw in a lightbulb?
        <br>
        A: Does it have to be a lightbulb?
      </div>
      <p>
        <a href="https://www.linkedin.com/in/michaelcohnmoreau/" target="_blank">
          Find me on LinkedIn
        </a>
      </p>
    </div>

    <!-- What I Use -->
    <div class="c-block c-tools">
      <hr class="c-block">
      <h3>What I Use</h3>

      <div class="o-layout">
        <div class="o-layout__item u-1/1 u-1/2@sm">
          <h4>Design Tools</h4>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/paper-and-pencil.jpg">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">Paper and pencil</div>
              <span class="o-caption__description">
                I like to start here. It slows me down, which is good sometimes.
              </span>
            </div>
          </div>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/figma-logo.png">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">Figma</div>
              <span class="o-caption__description">
                They really got collaboration right in the best way. Love it.
              </span>
            </div>
          </div>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/adobe-xd-logo.png">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">Adobe XD</div>
              <span class="o-caption__description">
                Has some serious limitations. Not my favorite, but gets the job done.
              </span>
            </div>
          </div>
        </div>

        <div class="o-layout__item u-1/1 u-1/2@sm">
          <h4>Front-End Development</h4>
            <div class="o-caption">
              <div class="o-caption__image">
                <img src="images/logos/vue-logo.png">
              </div>
              <div class="o-caption__text">
                <div class="o-caption__name">Vue</div>
                <span class="o-caption__description">
                  A home for everything and everything in its home. I'm a big fan.
                </span>
              </div>
            </div>
            <div class="o-caption">
              <div class="o-caption__image">
                <img src="images/logos/angular-logo.png">
              </div>
              <div class="o-caption__text">
                <div class="o-caption__name ng-binding">Angular</div>
                <span class="o-caption__description">
                  When I discovered it, it made me want to rewrite every site I had ever built.
                </span>
              </div>
            </div>
            <div class="o-caption">
              <div class="o-caption__image">
                <img src="images/logos/csswizardy-logo.png">
              </div>
              <div class="o-caption__text">
                <div class="o-caption__name">BEMIT</div>
                <span class="o-caption__description ng-binding" ng-bind-html="skill.description">
                  <a href="http://csswizardry.com/2015/08/bemit-taking-the-bem-naming-convention-a-step-further/">
                    CSS Wizardry's</a> rock solid organization and naming philosophy.
                </span>
              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- What I Have Used -->
    <div class="c-block c-tools">
      <hr class="c-block">
      <h3>What I Have Used</h3>

      <div class="o-layout">
        <!-- Prototyping -->
        <div class="o-layout__item u-1/2@sm">
          <h4>Prototyping</h4>
          
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/sketch-app-logo.jpg">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">Sketch</div>
              <span class="o-caption__description">
                Still one of my absolute favs. It will always have a special place in my heart.
              </span>
            </div>
          </div>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/InVision-icon-pink-400px.png">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name ng-binding">InVision</div>
              <span class="o-caption__description">
                This was a good program, it just had some hardcore competition.
              </span>
            </div>
          </div>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/illustrator-logo.png">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">Illustrator</div>
              <span class="o-caption__description">
                The work-horse; not always easy, but very strong.
              </span>
            </div>
          </div>
        </div>

        <!-- Programming -->
        <div class="o-layout__item u-1/2@sm">
          <h4>Programming</h4>          
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/jquery-logo.gif">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">jQuery</div>
              <span class="o-caption__description">
                Will always have a place in my heart; just so good at DOM manipulation.
              </span>
            </div>
          </div>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/php-logo.png">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">php</div>
              <span class="o-caption__description">
                Great for simple CRUD websites. This was my go-to for a long time.
              </span>
            </div>
          </div>
          <div class="o-caption">
            <div class="o-caption__image">
              <img src="images/logos/python-logo.png">
            </div>
            <div class="o-caption__text">
              <div class="o-caption__name">Python</div>
              <span class="o-caption__description">
                Fun fact: I taught a class in Python despite never having learned it.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- What I Used to Use Professionally -->
    <div class="c-block c-tools">
      <hr class="c-block">
      <h3>What I Used to Use In a Previous Life*</h3>
      <h4>Full Stack aka Systems Programming</h4>

      <div class="o-layout o-layout--nowrap o-caption u-text--small">
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">C/C++</div>
          <span class="o-caption__description">So powerful, but so many seg faults. Not the language to learn programming.</span>
        </div>
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">Java</div>
          <span class="o-caption__description">I never jumped on the band wagon; such a nightmare for UI, so many issues with versions.</span>
        </div>
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">VB and C#</div>
          <span class="o-caption__description">It was a living, although I do love ternary operators. I get the appeal, even if it was cludgy.</span>
        </div>
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">Perl</div>
          <span class="o-caption__description">Used for its original purpose: massive document manipulation.</span>
        </div>
      </div>

      <div class="o-layout o-layout--nowrap o-caption u-text--small">
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">XML/XSLT</div>
          <span class="o-caption__description">Power of templates with the simplicity of a nuclear reactor.</span>
        </div>
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">Oracle</div>
          <span class="o-caption__description">Meh, it’s a database.</span>
        </div>
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">MySql</div>
          <span class="o-caption__description">[shrug] It’s another database.</span>
        </div>
        <div class="o-layout__item o-caption__text">
          <div class="o-caption__name">OpenLaszlo</div>
          <span class="o-caption__description">Ever heard of this one? Ask me about it!</span>
        </div>
      </div>
    </div>

    <div class="o-footnote">
      <p>* Not a comprehensive list</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '../assets/scss/_mixins.scss';

.c-intro {
  max-width: 820px;
  margin: 0 auto;

  p {
    padding-bottom: 1rem;
  }

  img {
    @include mixins.pop;
  }

  .o-layout__item {
    padding-left: 0;
    padding-right: 20px;
  }
}

.c-bio {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  p {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 425px) {
}
</style>