<script setup>
    import { ref } from 'vue';

    const open = ref(false);

    defineProps({
        source: String, 
        caption: String, 
        imageClass: String
    });

    function zoom() {
        open.value = true;
        document.body.style.overflowY = 'hidden';
    }

    function shrink() {
        open.value = false;
        document.body.style.overflowY = 'auto';
    }
</script>

<template>
    <div class="c-imageholder" @click="zoom">
        <img :src="source" :class="[!imageClass ? '' : imageClass]">
    </div>
    <div class="c-caption">
        {{ caption }}
        <br />
        <span class="o-instruction">(🔎 Click to Zoom)</span>
    </div>

    <Teleport to="body">
        <div class="c-image-modal" v-if="open" @click="shrink">
            <div class="c-modal__overlay">
                <div class="c-modal__content">
                    <img :src="source" />
                    <div class="c-caption">
                        {{ caption }}
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style scoped>
.o-instruction {
    font-style: italic;
}

.c-image-modal {
    .c-modal__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: hidden;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(0,0,0,0.7);
        cursor: zoom-out;

        .c-modal__content {
            display: flex;
            flex-direction: column;

            img {
                border: 3px solid #FFF;
                outline: 1px #000;
                max-width: 90vw;
                max-height: 90vh;
            }

            .c-caption {
                margin: 0;
                background-color: #FEFEFE;
                color: #000;
                line-height: 2rem;
            }
        }
    }
}
</style>