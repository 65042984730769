import { createRouter, createWebHistory } from 'vue-router'

import PortfolioPage from '../views/PortfolioPage.vue';
import MedPhysicsPage from '../views/portfolio/MedPhysicsPage.vue';
import TestingPage from '../views/portfolio/TestingPage.vue';
import EvaluationPage from '../views/portfolio/EvaluationPage.vue';
import CanvasPage from '../views/portfolio/CanvasPage.vue';
import AtlasPage from '../views/portfolio/AtlasPage.vue';
import CICDPage from '../views/portfolio/CICDPage.vue';
import DataJourneyPage from '../views/portfolio/DataJourneyPage.vue';

import WritingPage from '../views/WritingPage.vue';
import AboutPage from '../views/AboutPage.vue';
import ResumePage from '../views/ResumePage.vue';

const routes = [
  {
    path: '/',
    redirect: '/about',
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PortfolioPage,
    children: [
      {
        path: 'MedPhysics',
        component: MedPhysicsPage,
      },
      {
        path: 'Testing',
        component: TestingPage,
      },
      {
        path: 'Evaluation',
        component: EvaluationPage,
      },
      {
        path: 'Canvas',
        component: CanvasPage,
      },
      {
        path: 'Atlas',
        component: AtlasPage,
      },
      {
        path: 'CICD',
        component: CICDPage,
      },
      {
        path: 'Datajourney',
        component: DataJourneyPage,
      },
    ],
  },
  {
    path: '/writing',
    name: 'Writing',
    component: WritingPage,
  },
  {
    path: '/resume',
    name: 'Resume',
    component: ResumePage,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, 
  //scrollBehavior (to, from, savedPosition) {
  scrollBehavior () {
    // always scroll to top
    return { top: 0 };
  }
})

export default router
