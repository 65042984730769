<template>
  <div class="c-blog__wrapper">

    <div class="c-heroimage">
      <img src="images/banner2.jpg">
    </div>

    <div class="c-blog">

      <div class="c-blog__piece">
        <div class="c-blog__body">
          <p>
            Here are 3 articles I have written on design.
            <a href="https://medium.com/@michael.c.moreau" target="_blank">
              You can read all of my articles on Medium.com</a>
          </p>
        </div>
      </div>

      <hr class="c-blog__piece">

      <div class="c-blog__piece">
        <a
          href="https://medium.com/user-experience-design-1/designing-with-experts-3ff2010e0678"
          target="_blank"
        >
          <h3>Designing with experts</h3>
          <p class="o-subtitle">Participatory design as a superpower</p>
          <div class="c-blog__body">
            <p>Working with experts can feel a little bit like swimming with dolphins: you can get 
              in the water, but you’ll have a hard time keeping up.</p>
            <p>Thankfully, this is something I have experience with. Working with experts, that is, 
              not swimming with dolphins.</p>
          </div>
        </a>
      </div>

      <hr class="c-blog__piece">

      <div class="c-blog__piece">
        <a
          href="https://medium.com/swlh/dark-patterns-autonomy-and-death-3019de5107bd"
          target="_blank"
        >
        <h3>Autonomy, Dark Patterns, and Death</h3>
          <div class="c-blog__body">
            <p>Previously, when I have written about design and industrial machinery, it was in the 
              context of failure to prevent errors. While this is an issue in all software, it’s 
              especially important in the industrial domain where mistakes can lead to damage to 
              equipment or, even worse, loss of life.</p>
          </div>
        </a>
      </div>

      <hr class="c-blog__piece">

      <div class="c-blog__piece">
        <a href="https://uxdesign.cc/never-email-designs-a0462a1dd1c1" target="_blank">
          <h3>Why you should never email your designs</h3>
          <p class="o-subtitle">A Better Way to Share Your Work</p>
          <div class="c-blog__body">
            <p>Last year, a diagram I had drawn was featured in a company-wide presentation. It was
              exciting to see something I had worked so hard on get such broad exposure, and I was
              proud to see it up on the screen. But as I looked closer, I cringed: the diagram they
              were using was out-of-date, by several versions.
            </p>
          </div>
        </a>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.c-blog {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .c-blog__timestamp {
    padding: 0;
    font-style: italic;
  }

  .c-blog__body p {
    margin-top: 1rem;
  }
}

.c-blog__piece {
  margin-top: 1.6rem;
}
</style>