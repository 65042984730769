<script setup>
  import { ref } from 'vue';

  const loggedIn = ref(false);
  const error = ref(false);
  const pass = ref("");

  // eslint-disable-next-line
  function isUserLoggedIn() {
    console.log("Is user logged in?");

    // Check to see if the user is logged in
    let user = sessionStorage.getItem("user");
    if (user) {
      // Hide the password disclaimer, show the protected content
      loggedIn.value = true;
    }
  }

  function login() {
    // Does the entered value match our super secret hidden password?
    if (pass.value === "Hello!") {
        // Store the username
        sessionStorage.setItem("user", "guest");

        // Close the disclaimer, show the content
        loggedIn.value = true;
    } else {
        // Show the error
        error.value = true;
    }
  }

  isUserLoggedIn();
</script>

<template>
    <div class="c-disclaimer">
        <h3>Disclaimer</h3>
        <p>
            <slot name="disclaimer" />
        </p>
        <p>
        For detailed case studies, or the password, 
        <a href="https://www.linkedin.com/in/michaelcohnmoreau/" target="_blank">
            please contact me</a>. Thank you.
        </p>

        <div class="c-disclaimer__passwordbox" v-if="!loggedIn">
            <label for="Password">Password:</label>
            <input type="password" v-model="pass" @keyup.enter="login" />
            <button @click="login">View</button>
            <div class="c-error" v-if="error">Oops, that wasn't right. Please try again.</div>
        </div>
    </div>

    <slot name="intro">
      Introduction
    </slot>
    <slot name="protected" v-if="loggedIn">
      CLASSIFIED
    </slot>

</template>

<style lang="scss" scoped>
  .c-disclaimer {
    padding: 1rem;
    border: 3px solid #A01E3C;
    text-align: center;
    border-radius: 0.5rem;
    background-color: #FFF;

    .c-disclaimer__passwordbox { 
      padding-bottom: 0.5rem;
    }

    h3 {
      color: #A01E3C;
      text-align: left;
      padding-top: 0;
    }

    h3, p {
      text-align: left;
      margin-bottom: 1rem;
    }

    label {
      padding-bottom: 0.5rem;
      padding-right: 0.5rem;
      font-weight: bold;
    }

    input {
      margin-right: 1rem;
      line-height: 2rem;
    }

    .c-error {
        margin-top: 0.5rem;
        color: red;

        a {
          border-bottom: 1px solid red;
        }
    }
  }
</style>