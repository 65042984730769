<script setup>
</script>

<template>
  <header class="c-header">
    <a href="/">
    <div class="o-media">
      <img src="/images/logo_shadow.png" />
      <div class="o-media__body c-nameplate">
        <div class="c-nameplate__top o-title">Michael <br />Moreau</div>
      </div>
    </div>
    </a>
  </header>

  <nav>
    <ul class="o-list-inline c-nav" id="c-nav">
      <li name="about" class="o-list-inline__item c-nav__item">
        <router-link to="/about" class="c-nav__link">About</router-link>
      </li>
      <li name="portfolio" class="o-list-inline__item c-nav__item">
        <router-link to="/portfolio" class="c-nav__link">Portfolio</router-link>
      </li>
      <li name="blog" class="o-list-inline__item c-nav__item">
        <router-link to="/writing" class="c-nav__link">Writing</router-link>
      </li>
      <li name="resume" class="o-list-inline__item c-nav__item">
        <router-link to="/resume" class="c-nav__link">Resume</router-link>
      </li>
    </ul>
  </nav>

  <div class="c-stage">
    <router-view />
  </div>

  <footer class="c-footer">
    <div class="container c-footer__copyright">
      &copy; Michael Moreau 2015-2024. Hand coded in Vue.js and SCSS.
    </div>
  </footer>
</template>

<style lang="scss">
@use 'assets/scss/_reset.scss';
@use 'assets/scss/_typography.scss';
@use 'assets/scss/_grid.scss';
@use 'assets/scss/_mixins.scss';
@use 'assets/scss/_elements.scss';
@use 'assets/scss/_objects.scss';

  /* **********************************************************************************************
   *
   * COMPONENTS
   *
   ********************************************************************************************* */
  
  .c-blog .c-blog__title,
  .c-nameplate, 
  .u-highlight {
      font-weight: 700
  }

  .c-block {
      margin-top: 40px;
      margin-bottom: 40px
  }

  .c-callout {
      font-size: 24px;
      font-size: 1.6rem;
      line-height: 1.6666666667;

      &.c-callout__center-align {
        text-align: center;
      }
  }

  .c-heroimage {
    max-width: 960px;
    margin: 20px auto;
    text-align: center;
  }

  .c-caption {
    margin-top: 12px;
    font-size: 12px;
    font-style: italic;
    text-align: center;

    &.c-caption__gallery {
      margin-top: 0;
    }
  }

  .c-modal {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    overflow-y: auto;

    .c-modal__overlay {
        background-color: #000;
        opacity: 0.7;
        width: 100vw;
        height: 100vh;
        position: fixed;
        cursor: zoom-out;
    }

    img {
        max-width: 80%;
        position: relative;
        margin: 0 auto;
        overflow-y: auto;
        margin: 2rem;

        //border: 3px solid #FFF;
        //outline: 1px #000;
    }
  }

  @media screen and (min-width: 720px) {

      .o-layout .o-layout__item {
        padding-bottom: 0;
      }

      .c-list {
        margin-left: -22px;
      }

      .c-callout {
        padding-top: 20px;
      }
  }

  .c-header {
    padding-top: 20px;

    .c-header__logo {
      box-shadow: 4px 4px 0px 0px #312e68
    }

    .c-inlinelogo {
      padding: 12px;
      border: 5px solid #222;
      font-weight: 700;
      font-size: 36px;
      line-height: 32px;
      word-spacing: -6px;
      text-align: right
    }
  }

  .c-nameplate {
    padding-left: 10px;
    color: #000;

    .c-nameplate__top {
      font-size: 72px;
      line-height: 58px;
      padding-top: 14px
    }

    .c-nameplate__bottom {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 4px;
      text-align: center
    }
  }

  .c-nav {
    margin-top: 14px;
    padding-top: 6px;
    padding-bottom: 12px;
    text-align: center;
    font-size: 18px;
    @include mixins.pagebg;

    .c-nav__item {
      padding-right: 10px;
      padding-left: 10px;

      @include mixins.push__prep;

      &:hover {
        @include mixins.push;
      }

      .c-nav__link {
        display: inline-block;
        font-weight: 400;
        color: #222;
        border-bottom: 1px solid transparent;

        &.router-link-active {
          border-bottom: 1px solid mixins.$color-bright-yellow;
        }

        &.router-link-active:hover {
          border-bottom: 1px solid transparent;
        }

        /*&:hover {
          border-bottom: 1px solid #d9d9d9;
        }*/
      }
    }
  }

  .c-stage {
      margin-left: auto;
      margin-right: auto;
      max-width: 960px;
  }

  .c-footer {
    margin-top: 40px;
    background-color: #d9d9d9;
    color: #b3b3b3;
    text-align: center;

    .c-footer__copyright {
      line-height: 40px
    }
  }

@media screen and (min-width: 425px) {
    .c-nav__item {
      padding-right: 20px;
      padding-left: 20px;
    }
}
</style>